<template>
  <ion-card
    class="m-1 mb-2"
    :button="true"
    :router-link="link"
    router-direction="forward"
  >
    <div class="flex items-center p-5">
      <div class="items-center mr-5">
        <ion-icon :icon="icon" class="text-3xl text-secondary" />
      </div>

      <div class="flex-1 min-w-0">
        <ion-card-header class="p-2 truncate">
          <ion-card-title class="text-base truncate">
            {{ label }}
          </ion-card-title>
        </ion-card-header>
      </div>
    </div>
  </ion-card>
</template>

<script>
import { IonCard, IonCardHeader, IonCardTitle, IonIcon } from '@ionic/vue'

export default {
  name: 'InfoTile',
  props: ['icon', 'label', 'link'],
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonIcon
  }
}
</script>
