
import { languageOutline, informationCircleOutline } from 'ionicons/icons'
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent
} from '@ionic/vue'
import InfoTile from '../components/InfoTile.vue'

export default {
  name: 'About',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    InfoTile
  },
  setup() {
    return {
      languageOutline,
      informationCircleOutline
    }
  }
}
